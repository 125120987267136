var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("button", {
    class: _vm.data.class,
    domProps: { textContent: _vm._s(_vm.$t(_vm.data.text)) },
    on: {
      click: function ($event) {
        $event.stopPropagation()
        $event.preventDefault()
        return _vm.data.click.apply(null, arguments)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }